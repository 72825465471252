@import "~@ui/styles/tools";
@import "@app/styles/variables";

.section {
    overflow: hidden;
}

.banner {
    position: relative;
    border-radius: 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    padding-right: 60px;

    @include media-desktop-md {
        padding-right: 40px;
    }

    @include media-laptop {
        padding-right: 24px;
    }

    @include media-tablet {
        padding-right: 16px;
    }

    @include media-mobile {
        flex-direction: column;
    }
}

.content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 44px;
    padding: 45px 0 45px 60px;

    @include media-desktop-md {
        padding: 30px 0 30px 40px;
        gap: 30px;
    }

    @include media-laptop {
        padding: 24px 0 24px 24px;
        gap: 24px;
    }

    @include media-tablet {
        padding: 16px 0 16px 16px;
        gap: 16px;
    }

    @include media-tablet-portrait {
        gap: 12px;
    }

    @include media-mobile {
        padding: 10px 20px;
        flex-direction: column;
        align-items: unset;
    }
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-laptop {
        gap: 12px;
    }

    @include media-tablet {
        gap: 8px;
    }
}

.description {
    color: $color-primary;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0;
    font-weight: 400;

    @include media-tablet {
        font-size: 16px;
    }

    @include media-tablet-portrait {
        font-size: 14px;
    }
}

.title {
    position: relative;
    z-index: 1;
    color: $color-title-active;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 1;
    font-weight: 600;
    max-width: 32ch;

    b, em, strong {
        font-weight: 600;
        color: $color-primary;
    }

    &_without-image {
        flex: unset;
    }

    @include media-desktop-md {
        font-size: 36px;
    }

    @include media-laptop {
        font-size: 32px;
    }

    @include media-tablet {
        font-size: 26px;
    }

    @include media-tablet-portrait {
        font-size: 20px;
    }
}

.button {
    font-size: 20px;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    line-height: 1;
    padding: 16px 20px;
    border-radius: 30px;

    @include media-laptop {
        font-size: 16px;
        padding: 12px 16px;
    }

    @include media-tablet {
        font-size: 14px;
        padding: 10px 12px;
    }

    > img {
        transform: unset;
        width: 24px;
        height: 24px;
        margin: 0 !important;

        @include media-laptop {
            width: 20px;
            height: 20px;
        }

        @include media-tablet {
            width: 16px;
            height: 16px;
        }
    }

    &-container {
        position: relative;
        z-index: 1;
        white-space: nowrap;
    }

    &-text {
        line-height: 1;
        margin: 0 !important;
    }
}

.image-container {
    position: relative;
    width: 500px;

    @include media-desktop-md {
        width: 400px;
    }

    @include media-laptop {
        width: 350px;
    }

    @include media-tablet {
        width: 300px;
    }

    @include media-tablet-portrait  {
        width: 210px;
    }

    @include media-mobile {
        height: 250px;
        width: auto;
    }

    @include media-mobile-xs {
        height: 180px;
    }
}

.image {
    height: auto !important;
    top: unset !important;

    @include media-mobile {
        max-width: 350px;
        left: 50% !important;
        transform: translateX(-50%);
    }

    @include media-mobile-xs {
        max-width: 250px;
    }
}
